import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import MarketingPage from "./marketing-page/MarketingPage";
import SignInSide from "./sign-in-side/SignInSide";
import Pixel from "./Pixel/Pixel";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<MarketingPage />} />
          <Route path="/website" element={<MarketingPage />} />
          <Route path="/signup" element={<SignInSide />} />
          <Route path="/redirect" element={<Pixel />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
