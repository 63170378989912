import mixpanel from "mixpanel-browser";

mixpanel.init("fc67cf21b62ca58a68928039c43571cc", {
  debug: false,
  track_pageview: true,
});

const trackEvent = (event: any, props = {}) => {
  event = "Website - " + event;
  mixpanel.track(event, props);
};

const buildUrl = () => {
  const env = process.env.NODE_ENV || "development";

  if (false && env === "development") {
    return "http://localhost:3000";
  }

  return "https://api.jinno.app";
};

const api = async (data: any) => {
  try {
    const response = await fetch(buildUrl() + "/api/userInfo/register", {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
    });
    return response.json();
  } catch (e) {}
};
export { api, trackEvent };
