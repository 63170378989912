import React, { useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { visuallyHidden } from "@mui/utils";

export default function Hero() {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.muted = true; // Ensure the video is muted for autoplay to work
      video.play().catch((error) => {
        console.error("Autoplay failed:", error);
      });
    }
  }, []);

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundImage:
          "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)",
        ...theme.applyStyles("dark", {
          backgroundImage:
            "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)",
        }),
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack
          spacing={2}
          useFlexGap
          sx={{ alignItems: "center", width: { xs: "100%", sm: "70%" } }}
        >
          <Typography
            variant="h1"
            sx={{
              textAlign: "center",
              alignItems: "center",
              fontSize: "clamp(3rem, 10vw, 3.5rem)",
            }}
          >
            Live preview any React component&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={(theme) => ({
                fontSize: "inherit",
                color: "primary.main",
                ...theme.applyStyles("dark", {
                  color: "primary.light",
                }),
              })}
            >
              inside VS Code
            </Typography>
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              color: "text.secondary",
              width: { sm: "100%", md: "80%" },
            }}
          >
            Jinno is a workshop for building React applications and pages in
            isolation with AI. Create and export your components in 1 click.
          </Typography>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: "100%", sm: "350px" } }}
          >
            {/* <InputLabel htmlFor="email-hero" sx={visuallyHidden}>
              Email
            </InputLabel>
            <TextField
              id="email-hero"
              hiddenLabel
              size="small"
              variant="outlined"
              aria-label="Enter your email address"
              placeholder="Your email address"
              fullWidth
              slotProps={{
                htmlInput: {
                  autoComplete: "off",
                  "aria-label": "Enter your email address",
                },
              }}
            /> */}
            <a
              href="https://marketplace.visualstudio.com/items?itemName=jinno.codelens-sample"
              target="blank"
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{
                  minWidth: "200px", // Optional: Define a larger minimum width
                  padding: "12px 24px", // Adjust padding for a bigger look
                  fontSize: "1rem", // Increase font size for better visibility
                }}
              >
                Add to Vs Code
              </Button>
            </a>
            <a
              href="https://chromewebstore.google.com/detail/jinno-code-any-react-comp/nggpkpfmdkbaakpndblpandmldendooa"
              target="blank"
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{
                  minWidth: "200px", // Optional: Define a larger minimum width
                  padding: "12px 24px", // Adjust padding for a bigger look
                  fontSize: "1rem", // Increase font size for better visibility
                }}
              >
                Add to Chrome
              </Button>
            </a>
          </Stack>
          {/* <Typography
            variant="caption"
            color="text.secondary"
            sx={{ textAlign: "center" }}
          >
            By clicking &quot;Start now&quot; you agree to our&nbsp;
            <Link href="#" color="primary">
              Terms & Conditions
            </Link>
            .
          </Typography> */}
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ textAlign: "center" }}
          >
            By clicking &quot;add to vs code &quot;or &quot;Add to chrome&quot;
            you agree to our&nbsp;
            <Link href="#" color="primary">
              Terms & Conditions
            </Link>
            .
          </Typography>
        </Stack>
        {/* Replace StyledBox with a video element */}
        <Box
          sx={{
            alignSelf: "center",
            width: "100%",
            height: "auto",
            marginTop: { xs: 8, sm: 10 },
            borderRadius: 1,
            overflow: "hidden",
            boxShadow: "0 0 12px 8px hsla(220, 25%, 80%, 0.2)",
          }}
        >
          <video
            ref={videoRef}
            src="https://www.jinno.app/assets/demo_gifs/Jinno%20demo%20vs%20code.mov"
            loop
            controls
            style={{
              width: "100%",
              borderRadius: "12px",
              objectFit: "cover",
            }}
          />
        </Box>
      </Container>
    </Box>
  );
}
