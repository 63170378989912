import React, { useEffect } from "react";
import { trackEvent } from "../utils";

const Pixel = () => {
  useEffect(() => {
    // Get URL parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Extract 'from' and 'redirect' parameters
    const from = urlParams.get("from");
    const redirectId = urlParams.get("redirect");
    let redirect: any = false;
    if (redirectId === "vs") {
      redirect =
        "https://marketplace.visualstudio.com/items?itemName=jinno.codelens-sample";
    } else if (redirectId === "chrome") {
      redirect =
        "https://chromewebstore.google.com/detail/jinno-code-any-react-comp/nggpkpfmdkbaakpndblpandmldendooa";
    }
    if (from) {
      // Check if both parameters exist
      // Save 'from' in localStorage
      trackEvent("pixel saved", { from });
      localStorage.setItem("referrer", from);
      localStorage.setItem(
        "referrer_timestamp",
        new Date().getTime().toString()
      );

      const { protocol, host } = window.location;
      const rootUrl = `${protocol}//${host}/`;
      //if the user didn't add redirect url it will redirect the user to the root website
      window.location.href = redirect ? redirect : rootUrl;
    }
  }, []);
  return <div>Redirecting</div>;
};
export default Pixel;
// http://localhost:3000/redirect?from=chintan
// http://localhost:3000/redirect?from=chintan&redirect=chrome
// http://localhost:3000/redirect?from=chintan&redirect=vs
