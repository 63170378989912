import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Stack from "@mui/material/Stack";
import SignInCard from "./SignInCard";
import AppTheme from "../shared-theme/AppTheme";
import ColorModeSelect from "../shared-theme/ColorModeSelect";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { api, trackEvent } from "../utils";

let sendPageLoadOnce = false;

export default function SignInSide(props: { disableCustomTheme?: boolean }) {
  const [showSignUp, setShowSignUp] = useState(true);
  const [success, setSuccess] = useState(false);
  const [userExistsError, setUserExistsError] = useState(false);

  useEffect(() => {
    window.addEventListener("message", (event) => {
      if (event.data.type && event.data.type === "from_extension") {
        const data = event && event.data ? event.data : {};
        const isLogin = data.isLogin;
        const mixpanelDistinctId = data.mixpanelDistinctId;

        if (!isLogin) {
          setSuccess(false);
          setShowSignUp(true);
        }

        if (data.mixpanelDistinctId) {
          localStorage.mixpanelDistinctId = mixpanelDistinctId;
          if (!sendPageLoadOnce) {
            sendPageLoadOnce = true;
          }
        }

        setShowSignUp(!isLogin);
      }
    });
  }, []);

  const onSignUp = async (email: string, password: string) => {
    const referrer = localStorage.referrer;
    const referrer_timestamp =
      localStorage.referrer_timestamp && Number(localStorage.referrer_timestamp)
        ? Number(localStorage.referrer_timestamp)
        : new Date();
    const data = await api({
      email: email ? email.trim() : null,
      password: password ? password.trim() : null,
      referrer,
      referrer_timestamp,
    });
    trackEvent("Signup success");

    if (data.error === "User already exists") {
      setUserExistsError(true);
      return;
    }

    if (data.success) {
      let email;
      let session;
      if (data.success.email) {
        email = data.success.email;
      }
      if (data.success.session) {
        session = data.success.session;
      }

      setSuccess(true);
      window.postMessage(
        {
          type: "from_website",
          message: "login",
          email: email,
          session: session,
        },
        "*"
      );
    }
  };

  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <ColorModeSelect sx={{ position: "fixed", top: "1rem", right: "1rem" }} />
      <Stack
        direction="column"
        component="main"
        sx={[
          {
            justifyContent: "center",
            height: "calc((1 - var(--template-frame-height, 0)) * 100%)",
            marginTop: "max(40px - var(--template-frame-height, 0px), 0px)",
            minHeight: "100%",
          },
          (theme) => ({
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              zIndex: -1,
              inset: 0,
              backgroundImage:
                "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
              backgroundRepeat: "no-repeat",
              ...theme.applyStyles("dark", {
                backgroundImage:
                  "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))",
              }),
            },
          }),
        ]}
      >
        <Stack
          direction={{ xs: "column-reverse", md: "row" }}
          sx={{
            justifyContent: "center",
            gap: { xs: 6, sm: 12 },
            p: 2,
            mx: "auto",
          }}
        >
          <Stack
            direction={{ xs: "column-reverse", md: "row" }}
            sx={{
              justifyContent: "center",
              gap: { xs: 6, sm: 12 },
              p: { xs: 2, sm: 4 },
              m: "auto",
            }}
          >
            {showSignUp && !success ? (
              <>
                <SignInCard onSignUp={onSignUp} userExistsError={userExistsError} />
              </>
            ) : (
              <div>
                {success && !showSignUp ? `Signup successful!` : `You are already logged in`}
                <SignUpSuccess />
              </div>
            )}
          </Stack>
        </Stack>
      </Stack>
    </AppTheme>
  );
}

const SignUpSuccess = () => (
  <Stack
    direction={{ xs: "column", sm: "row" }}
    spacing={1}
    useFlexGap
    sx={{ pt: 2, width: { xs: "100%", sm: "350px" } }}
  >
    <a
      href="https://marketplace.visualstudio.com/items?itemName=jinno.codelens-sample"
      target="blank"
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        sx={{
          minWidth: "200px", // Optional: Define a larger minimum width
          padding: "12px 24px", // Adjust padding for a bigger look
          fontSize: "1rem", // Increase font size for better visibility
        }}
      >
        Add to Vs Code
      </Button>
    </a>
    <a
      href="https://chromewebstore.google.com/detail/jinno-code-any-react-comp/nggpkpfmdkbaakpndblpandmldendooa"
      target="blank"
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        sx={{
          minWidth: "200px", // Optional: Define a larger minimum width
          padding: "12px 24px", // Adjust padding for a bigger look
          fontSize: "1rem", // Increase font size for better visibility
        }}
      >
        Add to Chrome
      </Button>
    </a>
  </Stack>
);
